import { useAuth0, } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
 
function App() {
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    logout,
    getAccessTokenSilently
  } = useAuth0();
 
  const [ accessToken, setAccessToken ] = useState("");


  useEffect(() => {

    if (isAuthenticated && accessToken.length === 0) {
      getAccessTokenSilently().then(token => setAccessToken(token));
    }

  }, [ getAccessTokenSilently, isAuthenticated, accessToken ]);

  if (isLoading) {
    return <div>loading...</div>;
  }

  if (error) {
    return <div>oops... {error.message}</div>;
  }

  if (isAuthenticated) {

    if (!accessToken) {
      return <div>fetching access token.</div>
    }

    return (
      <div>Hello {user.name}{' '} <button onClick={() => logout({ returnTo: window.location.origin })}>Log out</button></div>
    );

  }
  else {
    loginWithRedirect({connection : 'employee' })
    return <div>logging you in ...</div>
  }

}
 
export default App;